import {
  Layout,
  Image,
  Tabs,
  Menu,
  Dropdown,
  message,
  Button,
  Avatar,
  Space,
  notification,
  Modal,
  List,
  Tooltip,
  Row,
  Col,
  Badge,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import defaultLocale from "../../i18n/default";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import {
  BarsOutlined,
  CaretRightOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import ProfilePanel from "../home/ProfilePanel";
import ChangePwdPanel from "../home/ChangePwdPanel";
import QuestionList from "./onlineQuestion";
import { Footer } from "antd/lib/layout/layout";
import i18n from "./i18n";
import picPlaceholder from "../../assets/images/pic-placeholder.png";
import defaultAvatar from "../../assets/images/avatar-default.png";
import useGuide from "../../hooks/useGuide";
import MyQuestion from "./myQuestion";
import {
  listQaAllByLogin,
  listCommentByHaveReadAndUserId,
} from "../../api/NewUserController";
import { getItem, setItem } from "../../utils/storage";
import { useSystemInfo } from "../../hooks/useSystemInfo";
import { RichDisplay } from "../components/RichEditor";
import { listSubject } from "../../api/SubjectController";
import LabList from "./LabList";
import type { SubjectType } from "../system/declare";
import ArticalList from "./articalList";
import NoticePopover from "../components/NoticePopover";
import useNotice from "../../hooks/useNotice";

const menuClasses: any = {
  3: "tabpanel-details",
  4: "tabpanel-questions",
};

const DeclarePage = () => {
  const { user, logout } = useAuth();
  const { sysInfo, baseInfo } = useSystemInfo();
  const [askShow, setAskShow] = useState(false);
  const { t, lang, setLang } = useLocale(i18n);
  const navigate = useNavigate();
  // 个人资料
  const [personalShow, setPersonalShow] = useState(false);
  // 修改密码
  const [pswShow, setPswShow] = useState(false);
  //实验结果
  const [loginQaList, setLoginQaList] = useState<any[]>([]);
  const [unreadVisible, setUnreadVisible] = useState(false);
  const [unreadList, setUnreadList] = useState<any[]>([]);
  // 菜单与内容
  const [projectList, setProjectList] = useState<SubjectType[]>();
  const [crtProject, setCrtProject] = useState<SubjectType>();
  const { count } = useNotice();

  const getProjectList = useCallback(
    () =>
      listSubject({ lang }).then(({ data }) => {
        if (data.code) {
          setProjectList(data.data);
          setCrtProject(data.data[0]);
        } else {
          setProjectList([]);
          setCrtProject(undefined);
          data?.msg && message.error(data.msg);
        }
        return data;
      }),
    [lang]
  );

  const changeLanguage = (language: SupportedLangType) => {
    if (language !== lang) {
      setLang(language);
      const nextLang = defaultLocale[language];
      message.info(`${nextLang["system.msg.switchlang"]} ${language}`);
    }
  };

  const dologout = () =>
    logout()
      .then((_res) => {
        navigate("/");
        message.success(t("system.msg.logout.success"));
      })
      .catch((err) => {
        console.log(err);
        err?.msg && message.error(err.msg);
      });

  const steps = [
    {
      element: "#header-language",
      popover: {
        title: t("intro.1.title"),
        description: t("intro.1.des"),
        position: "left",
      },
    },
    {
      element: "#header-user-actions",
      popover: {
        title: t("intro.2.title"),
        description: t("intro.2.des"),
        position: "left",
      },
    },
    {
      element: "#tabpanel-details",
      popover: {
        title: t("intro.3.title"),
        description: t("intro.3.des"),
        position: "bottom",
      },
    },
    {
      element: "#tabpanel-questions",
      popover: {
        title: t("intro.4.title"),
        description: t("intro.4.des"),
        position: "right",
      },
    },
  ];

  const { startGuide } = useGuide(steps);
  const haveRead = async () => {
    const { data } = await listCommentByHaveReadAndUserId({});
    if (data.code) {
      if (data.data.length > 0) {
        setUnreadList(data.data);
        setUnreadVisible(true);
      }
    }
  };

  const guideController = () => {
    const isTips = getItem("tips");
    if (user?.logNumber && user?.logNumber >= 2) return;
    if (isTips) return;

    setItem("tips", "1");

    notification.open({
      key: "1",
      message: t("intro.title"),
      duration: 6,
      icon: <QuestionOutlined />,
      description: t("intro.des"),
      btn: (
        <Space>
          <Button onClick={() => notification.close("1")}>
            {t("intro.btn.cancel")}
          </Button>
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              notification.close("1");
              startGuide();
            }}
          >
            {t("intro.btn.ok")}
          </Button>
        </Space>
      ),
    });
  };

  //登录人自己的提问列表
  const LoginQaList = async () => {
    const { data } = await listQaAllByLogin({ page: 1, rows: 10 });
    if (data.code) {
      setLoginQaList(data.data.rows);
    }
  };

  useEffect(() => {
    guideController();
    LoginQaList();
    haveRead();
    getProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const Content = {
    0: <SubContent project={crtProject} />,
    1: <RichDisplay html={crtProject?.contentText} />,
    2: <MessageBox project={crtProject} />,
    3: <LabList />,
    4: <QuestionList />,
    6: <ArticalList contentText={crtProject?.contentText} />,
  };

  return (
    <Layout className="declare-page flex flex-col h-screen">
      <div className="bg-white">
        <Row className="w-[1050px] mx-auto min-h-16 px-4 leading-none z-5 flex items-center justify-between select-none">
          <Col span={18} className="flex items-center">
            <Image
              src={sysInfo?.logoDeclare}
              preview={false}
              className="h-12.5 object-contain max-w-70"
              alt=""
              height={60}
              fallback={picPlaceholder}
            />
            <span
              className="ml-6 text-xl font-bold"
              title={baseInfo?.projectName}
            >
              {baseInfo?.projectName}
            </span>
          </Col>
          <Col span={6} className="text-right">
            <Tooltip title={t("header.tips.guide")}>
              <Button
                shape="circle"
                className="header-action-btn mr-3"
                icon={<FontAwesomeIcon icon={["far", "question-circle"]} />}
                onClick={(e) => {
                  e.stopPropagation();
                  startGuide();
                }}
              />
            </Tooltip>
            <Dropdown
              className="mr-3"
              arrow
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: 1,
                    label: "简体中文",
                    onClick: () => changeLanguage("zh-CN"),
                  },
                  {
                    key: 2,
                    label: "English",
                    onClick: () => changeLanguage("en-US"),
                  },
                ],
              }}
            >
              <Button
                shape="circle"
                id="header-language"
                className="header-action-btn"
                icon={<FontAwesomeIcon icon={["fas", "language"]} />}
              />
            </Dropdown>
            <NoticePopover>
              <Badge count={count?.unread} size="small" className="mr-2">
                <Button
                  shape="circle"
                  id="header-notification"
                  className="header-action-btn"
                  icon={<FontAwesomeIcon icon={["far", "bell"]} />}
                />
              </Badge>
            </NoticePopover>
            <Dropdown
              arrow
              placement="bottomRight"
              overlayClassName="!min-w-20"
              menu={{
                items: [
                  {
                    key: 1,
                    label: t("header.item.profile"),
                    icon: <BarsOutlined />,
                    onClick: () => setPersonalShow(true),
                  },
                  {
                    key: 2,
                    label: t("header.item.password"),
                    icon: <UnlockOutlined />,
                    onClick: () => setPswShow(true),
                  },
                  {
                    key: 3,
                    label: t("header.item.question"),
                    icon: <QuestionCircleOutlined />,
                    onClick: () => setAskShow(true),
                  },
                  { type: "divider" },
                  {
                    key: 4,
                    label: t("header.item.logout"),
                    icon: (
                      <FontAwesomeIcon
                        icon="power-off"
                        color="#FF5C5F"
                        className="text-l"
                      />
                    ),
                    onClick: dologout,
                  },
                ],
              }}
            >
              <div
                id="header-user-actions"
                className="cursor-pointer hover:bg-gray-200 p-1 rounded inline-block"
              >
                <Avatar
                  size={32}
                  src={user?.myac}
                  icon={<img src={defaultAvatar} alt="avatar" />}
                />
                <span className="ml-2 text-sm">{user?.name}</span>
              </div>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <div className="banner">
        <Image
          src={sysInfo?.banner}
          preview={false}
          className="banner-cover w-full"
          alt=""
        />
      </div>
      <div className="content flex-grow">
        <div className="content-container">
          <Tabs
            defaultActiveKey="1"
            onChange={(e) => {
              setCrtProject(projectList?.find((p) => String(p.id) === e));
            }}
            size="large"
            items={projectList?.map((p) => ({
              key: String(p.id),
              label: menuClasses.hasOwnProperty(p.subjectType) ? (
                <div id={menuClasses[p.subjectType]}>{p.subjectName}</div>
              ) : (
                p.subjectName
              ),
              children: Content[p.subjectType],
            }))}
          />
        </div>
      </div>
      <Footer className="bg-gray-900 py-4 text-center text-gray-400">
        <RichDisplay html={baseInfo?.footer} />
      </Footer>
      <ProfilePanel visible={personalShow} setVisible={setPersonalShow} />
      <ChangePwdPanel visible={pswShow} setVisible={setPswShow} />
      <MyQuestion
        visible={askShow}
        setVisible={setAskShow}
        dataSource={loginQaList}
      />
      <Modal
        title={t("declare.item.remind")}
        footer={null}
        destroyOnClose
        open={unreadVisible}
        forceRender
        width={500}
        onCancel={() => {
          setUnreadVisible(false);
        }}
      >
        <List
          dataSource={unreadList}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={
                  <div className="text-base">
                    {item.qaTitle}
                    <span
                      className="ml-5 text-red-500 cursor-pointer"
                      onClick={() => {
                        setAskShow(true);
                        setUnreadVisible(false);
                      }}
                    >
                      {item.countNumber} {t("table.pagination.total")}
                      {t("declare.item.unread")}
                    </span>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </Layout>
  );
};

const SubContent = ({ project }: { project?: SubjectType }) => {
  const [crtChild, setCrtChild] = useState<
    SubjectType | SubjectType["child"][number]
  >();

  useEffect(() => {
    setCrtChild(project?.child[0]);
  }, [project]);

  return (
    <div className="flex">
      <div className="sub-menu">
        <div className="menu-head">
          <Space>{project?.subjectName}</Space>
        </div>
        {project?.child.map((item) => (
          <div
            className="menu-item"
            key={item.id}
            onClick={() => setCrtChild(item)}
            style={
              crtChild?.id === item.id
                ? {
                    color: "#3671c9",
                    backgroundColor: "#ccc",
                    borderLeft: "3px solid #3671c9",
                  }
                : undefined
            }
          >
            {item.subjectName}
            {crtChild?.id === item.id && (
              <CaretRightOutlined className="menu-item-indicator" />
            )}
          </div>
        ))}
      </div>
      <div className="ml-8 bg-white flex-grow p-6 mb-6 rounded-sm">
        {crtChild?.subjectType === 1 && (
          <RichDisplay html={crtChild.contentText} className="rich-render" />
        )}
        {crtChild?.subjectType === 2 && <MessageBox project={crtChild} />}
        {crtChild?.subjectType === 3 && <LabList />}
        {crtChild?.subjectType === 4 && <QuestionList />}
        {crtChild?.subjectType === 6 && (
          <ArticalList contentText={crtChild.contentText} />
        )}
      </div>
    </div>
  );
};

const MessageBox = ({
  project,
}: {
  project?: SubjectType | SubjectType["child"][number];
}) => {
  useEffect(() => {
    try {
      const { tipContent, tipTitle } = JSON.parse(project?.contentText);

      Modal.info({
        title: tipTitle,
        content: tipContent,
        okText: "确定",
      });
    } catch (err) {}
  }, [project]);

  return <></>;
};

export default DeclarePage;
